import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
  Input,
  Button,
  CardActions,
  Typography,
  Divider,
} from '@material-ui/core'

import SweetAlert from 'sweetalert2-react'

import RegularCard from '../Cards/RegularCard.js'
import { expert } from '../../actions'
import CustomMultiSelect from 'components/Input/CustomMultiSelect.js'
import CustomTextField from 'components/Input/CustomTextField.js'

// @TODO: Add loading spinner (see CategoryForm)

const form = 'expert'
const selector = formValueSelector(form)

const onSubmit = (values, dispatch, props) => {
  values.objectId = props.initialValues.objectId
  values.user_id = props.initialValues.user_id
  values.accountNumber = parseInt(values.accountNumber, 0)
  props.dispatch(expert.save(values))
}

class ExpertsForm extends Component {
  handleChangeImage(e) {}

  componentDidMount() {
    this.props.dispatch(expert.getRegions())
    if (this.props.match.params.id) {
      this.props.dispatch(expert.get(this.props.match.params.id))
    }
  }
  hideAlert(event) {
    this.props.dispatch(expert.hideAlert())
    this.props.history.push('/expert')
  }
  render() {
    const {
      firstNameValue,
      lastNameValue,
      regions,
      handleSubmit,
      saved,
      errorMessage,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') +
      firstNameValue +
      ' ' +
      lastNameValue
    return (
      <div>
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                style={{ textAlign: 'center' }}
                gutterBottom
              >
                Personal Details
              </Typography>
              <Divider style={{ marginBottom: '20px' }} />
              <div>
                <Field
                  name="user.firstName"
                  component={CustomTextField}
                  label="First Name"
                />
              </div>
              <div>
                <Field
                  name="user.lastName"
                  component={CustomTextField}
                  label="Last Name"
                />
              </div>
              {this.props.match.params.id ? null : (
                <div>
                  <div>
                    <Field
                      name="user.email"
                      component={CustomTextField}
                      label="Email"
                    />
                  </div>
                  <div>
                    <Field
                      name="user.password"
                      component={CustomTextField}
                      type="password"
                      label="Password"
                    />
                  </div>
                </div>
              )}
              <div>
                <label>Regions</label>
                <Field
                  name="expertRegions"
                  label="Regions"
                  placeholder="Select regions"
                  options={regions.map(r => ({
                    label: r.regionName,
                    value: r.objectId,
                  }))}
                  component={CustomMultiSelect}
                  format={values =>
                    values.map(value => ({
                      value: value.objectId,
                      label: value.regionName,
                    }))
                  }
                  normalize={values => {
                    return (
                      regions &&
                      regions.filter(
                        region =>
                          values &&
                          values.findIndex(
                            val => val.value === region.objectId
                          ) > -1
                      )
                    )
                  }}
                  selectInput={<Input id="select-multiple-chip" />}
                  multiple
                />
              </div>
              <div>
                <Field name="job" component={CustomTextField} label="Job" />
              </div>
              <div>
                <Field
                  name="phoneNumber"
                  component={CustomTextField}
                  label="Phone Number"
                />
              </div>

              <Typography
                variant="h5"
                style={{ textAlign: 'center', marginTop: 10 }}
                gutterBottom
              >
                Account Details
              </Typography>
              <Divider style={{ marginBottom: '20px' }} />
              <div>
                <Field
                  name="accountName"
                  component={CustomTextField}
                  label="Account Name"
                />
              </div>
              <div>
                <Field
                  name="accountNumber"
                  component={CustomTextField}
                  label="Account Number"
                />
              </div>
              <div>
                <Field
                  name="paypalAccount"
                  component={CustomTextField}
                  label="Paypal Account"
                />
              </div>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/expert')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
        <SweetAlert
          type={errorMessage ? 'error' : 'success'}
          show={saved || (errorMessage ? true : false)}
          title={errorMessage ? 'Error' : 'Notice'}
          text={errorMessage ? errorMessage : 'Saved'}
          onConfirm={this.hideAlert.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  record: state.expert.record,
  firstNameValue: selector('user.firstName', state),
  lastNameValue: selector('user.lastName', state),
  regions: state.expert.regions,
  fetching: state.expert.fetching,
  fetched: state.expert.fetched,
  saving: state.expert.saving,
  saved: state.expert.saved,
  errorMessage: state.expert.errorMessage,
  initialValues: state.expert.record,
})

export default connect(mapStateToProps)(
  reduxForm({
    // a unique name for the form
    form,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ExpertsForm)
)
